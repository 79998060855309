// **  Initial State
const initialState = {

};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EARNINGS":
      return {
        ...state,
        earning: action.data,
      };
    case "GET_USERS_STATS":
      return {
        ...state,
        usersStats: action.data,
      };

    case "GET_TASK_STATS":
      return {
        ...state,
        taskStats: action.data,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
