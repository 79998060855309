// **  Initial State
const initialState = {
  counts: {},
  affiliateTransactions: {},
  refCodes: {},
  affiliateHistory: {},
  users: {},
  userInfo: {},
  riskCheck: {},
  userTasks: {},
  // userBlocks:[]
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS":
      return {
        ...state,
        users: action.data,
      };

    case "GET_USER":
      console.log("action", action);
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                ...action.data.data[0],
              },
            }
            : {
              ...(state.userInfo ? state.userInfo : {}),
              [action.params.user_id]: { ...action.data.data[0] },
            },
      };

    case "UPDATE_USER":
      console.log("action", action);
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                ...action.data.data,
              },
            }
            : {
              ...(state.userInfo ? state.userInfo : {}),
              [action.params.user_id]: { ...action.data.data },
            },
      };

    case "GET_ADMIN_USERS":
      return {
        ...state,
        admins: action.data,
        adminsObj: action.callBackData,
      };

    case "GET_MERCHANT":
      return {
        ...state,
        merchant: action.data,
      };

    case "GET_OFFERS":
      return {
        ...state,
        offers: action.data,
      };

    case "UPDATE_OFFERS":
      return {
        ...state,
        offers: action.payload,
      };
    case "UPDATE_TRANSACTIONS":
      return {
        ...state,
        transactions: action.payload,
      };

    case "GET_TRANSACTIONS":
      return {
        ...state,
        transactions: action.data,
      };

    case "GET_WITHDRAWALS":
      return {
        ...state,
        withdrawals: action.data,
      };

    case "GET_TICKET":
      return {
        ...state,
        ticket: action.data,
      };

    case "GET_BETS":
      return {
        ...state,
        bets: action.data,
      };
    case "GET_STATS":
      return {
        ...state,
        stats: action.data,
      };

    case "GET_PROMO":
      return { ...state, promo: action.data };

    case "ADD_PROMO":
      return { ...state, promo: [action.data, ...state.promo] };

    case "GET_PROMO_USERS":
      return { ...state, promoUsers: action.data };

    case "GET_USERS_ACTVIVITY":
      return { ...state, usersActivity: action.data.data };

    case "GET_MONITORS":
      return {
        ...state,
        monitors: action.data,
      };

    case "GET_USER_ACTIVITY":
      console.log("action", action);
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                userActivity: action.data,
              },
            }
            : { [action.params.user_id]: { userActivity: action.data } },
      };

    case "GET_USER_KYC_VERIFICATIONS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                userKYC: action.data,
              },
            }
            : { [action.params.user_id]: { userKYC: action.data } },
      }

    case "GET_USER_TICKETS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                userTickets: action.data,
              },
            }
            : { [action.params.user_id]: { userTickets: action.data } },
      };

    case "GET_USER_AFFILIATE_HISTORY":
      return {
        ...state,
        affiliateHistory: {
          ...state.affiliateHistory,
          [action.params.referrer_id]: action.data,
        },
      };

    case "GET_USER_CODES":
      return {
        ...state,
        refCodes: {
          ...state.refCodes,
          [action.params.user_id]: action.data,
        },
      };

    case "GET_USER_AFFILIATE_TRANSACTIONS":
      return {
        ...state,
        affiliateTransactions: {
          ...state.affiliateTransactions,
          [action.params.referrer_id]: action.data,
        },
      };

    case "USER_RISK_CHECK":
      const { params } = action;
      return {
        ...state,
        riskCheck: {
          ...state.riskCheck,
          [params.split("user_id=")[1].split("&")[0]]: action.data,
        },
      };

    case "GET_USER_SETTINGS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                userSettings: action.data,
              },
            }
            : { [action.params.user_id]: { userSettings: action.data } },
      };

    case "GET_USER_INVENTORY":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                userInventory: action.data,
              },
            }
            : { [action.params.user_id]: { userInventory: action.data } },
      };

    case "GET_USER_INVENTORY_HISTORY":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.userId]
            ? {
              ...state.userInfo,
              [action.params.userId]: {
                ...state.userInfo[action.params.userId],
                userInventoryHistory: action.data,
              },
            }
            : { [action.params.userId]: { userInventoryHistory: action.data } },
      };

    case "GET_USER_BALLANCE_HISTORY":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.userId]
            ? {
              ...state.userInfo,
              [action.params.userId]: {
                ...state.userInfo[action.params.userId],
                userBalanceHistory: action.data,
              },
            }
            : { [action.params.userId]: { userBalanceHistory: action.data } },
      };

    case "GET_USER_OFFERS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.id]
            ? {
              ...state.userInfo,
              [action.id]: {
                ...state.userInfo[action.id],
                userOffers: action.data,
              },
            }
            : { [action.id]: { userOffers: action.data } },
      };

    case "GET_USER_TRANSACTIONS":
      return {
        ...state,
        userTransactions: {
          ...state.userTransactions,
          [action.params.user_id]: action.data,
        },
      };

    case "GET_USER_WITHDRAWALS":
      return {
        ...state,
        userWithdrawals: {
          ...state.userWithdrawals,
          [action.params.user_id]: action.data,
        },
      };

    case "GET_USER_BALLANCE":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                userBalance: action.data,
              },
            }
            : { [action.params.user_id]: { userBalance: action.data } },
      };

    case "GET_USER_TASKS":
      return {
        ...state,
        userTasks: {
          ...state.userTasks,
          [action.params.user_id || action.params.get("user_id")]: action.data,
        },
      };

    case "UPDATE_USER_BALANCE":
      console.log("action", action);
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.data.data.user_id]
            ? {
              ...state.userInfo,
              [action.data.data.user_id]: {
                ...state.userInfo[action.data.data.user_id],
                balance:
                  state.userInfo[action.data.data.user_id].balance +
                  +action.data.data.amount,
              },
            }
            : {
              [action.data.user_id]: {
                balance:
                  state.userInfo[action.data.data.user_id].balance +
                  +action.data.data.amount,
              },
            },
      };

    case "GET_USER_AFF_BONUS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                userAffiliateBonus: action.data,
              },
            }
            : { [action.params.user_id]: { userAffiliateBonus: action.data } },
      };
    case "GET_USER_PROMOCODE":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
              ...state.userInfo,
              [action.params.user_id]: {
                ...state.userInfo[action.params.user_id],
                userPromocode: action.data,
              },
            }
            : { [action.params.user_id]: { userPromocode: action.data } },
      };

    case "GET_USER_BETS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.id]
            ? {
              ...state.userInfo,
              [action.id]: {
                ...state.userInfo[action.id],
                userBets: action.data,
              },
            }
            : { [action.id]: { userBets: action.data } },
      };

    case "GET_BOT_SETTINGS":
      return {
        ...state,
        botSetings: action.data,
      };

    case "GET_MARKET_TRANSACTIONS":
      return {
        ...state,
        marketTransactions: action.data,
      };

    case "UPDATE_BOT_SETTINGS":
    case "DELETE_BOT_SETTINGS":
      return {
        ...state,
        botSetings: action.callBackData
          ? action.callBackData
          : state.botSetings,
      };

    case "COUNT":
      return {
        ...state,
        counts: { ...state.counts, [action.name]: action.count },
      };

    case "GET_USERS_COUNT":
      return {
        ...state,
        userCounts: {
          ...state.userCounts,
          [action.id]: action.data.count_users,
        },
      };

    case "GET_PROVIDERS":
      return {
        ...state,
        providers: action.callBackData ? action.callBackData : action.data,
      };
    case "GET_TASKS":
      return {
        ...state,
        tasks: action.callBackData ? action.callBackData : action.data,
      };

    case "GET_USER_BLOCKS":
      return {
        ...state,
        userBlocks: action.callBackData ? action.callBackData : action.data,
      };

    case "GET_ACHIEVMENTS":
      return {
        ...state,
        achievments: action.callBackData ? action.callBackData : action.data,
      };

    case "GET_WHEELS":
      return {
        ...state,
        wheels: action.callBackData ? action.callBackData : action.data,
      };

    case "GET_DIAL_CODES":
      return {
        ...state,
        dialCodes: action.callBackData ? action.callBackData : action.data,
      };

    case "GET_PROVIDER_LABELS":
      return {
        ...state,
        providerLabels: action.callBackData ? action.callBackData : action.data,
      };
    case "GET_PROVIDER_INSTRUCTIONS":
      return {
        ...state,
        providerInstructions: action.callBackData
          ? action.callBackData
          : action.data,
      };

    default:
      return state;
  }
};

export default dataReducer;
