// **  Initial State
const initialState = {};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TICKETS_INFO":
      return {
        ...state,
        ticketsInfo: action.data,
      };
      case "GET_TICKETS":
        return {
          ...state,
          tickets: action.data,
        };

    default:
      return state;
  }
};

export default ticketsReducer;
