// **  Initial State
const initialState = {
  translations: {},
};

const translationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CATEGORIES":
      return {
        ...state,
        categories: action.data,
      };
      case "GET_LANGUAGES":
      return {
        ...state,
        languages: action.data,
      };

    case "GET_TRANSLATIONS":
      return {
        ...state,
        translations: {
          ...state.translations,
          ...action.callBackData,
        },
      };

    case "UPDATE_TRANSLATION":
    case "DELETE_TRANSLATION":
      return {
        ...state,
        translations: action.callBackData
          ? action.callBackData
          : state.translations,
      };

    default:
      return state;
  }
};

export default translationsReducer;
