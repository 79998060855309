// **  Initial State
const initialState = {
  eventLeaderBoard: {},
  eventPrizes: {}
}

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EVENTS":
      return {
        ...state,
        events: action.data
      };


    case "EVENT_LEADER_BOARD":
      return {
        ...state,
        eventLeaderBoard: {
          ...state.eventLeaderBoard,
          [action.params.leader_board_id]: action.data
        }
      }
    case "GET_EVENT_PRIZES":
      console.log('action', action);
      return {
        ...state,
        eventPrizes:
        {
          ...state.eventPrizes,
          [action.params.leader_board_id]: action.callBackData ? action.callBackData : action.data
        }
      }

      case "UPDATE_EVENT_PRIZES":
        console.log('action', action);
        return {
          ...state,
          eventPrizes:
          {
            ...state.eventPrizes,
            [action.callBackData ? action.data.leader_board_id :  action.params.leader_board_id ]: action.callBackData ? action.callBackData : action.data
          }
        }

        case "DELETE_EVENT_PRIZES":
          console.log('action', action);
          return {
            ...state,
            eventPrizes:
            {
              ...state.eventPrizes,
              ...action.callBackData
            }
          }
    default:
      return state;
  }
};

export default eventsReducer;
