// **  Initial State
const initialState = {
  giftCards: {},
};

const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_RESTRICTIONS":
      console.log("action", action);
      return {
        ...state,
        restrictions: action.callBackData ? action.callBackData : action.data,
      };

    case "GET_GIFT_CARD_CATEGORIES":
      return {
        ...state,
        giftCardCategories: action.callBackData
          ? action.callBackData
          : action.data,
      };

    case "GET_GIFT_CARDS":
      console.log("action", action);
      return {
        ...state,
        giftCards: action.callBackData
          ? action.callBackData
          : { ...state.giftCards, [action.params.category_id]: action.data },
      };
    case "GET_FIAT_CURRENCIES":
      return {
        ...state,
        fiatCurrencies: action.callBackData ? action.callBackData : action.data,
      };

    default:
      return state;
  }
};

export default paymentsReducer;
