// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store';

import ability from './configs/acl/ability'
// import { Ability } from '@casl/ability'
import { AbilityContext } from './utility/context/Can'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';
import { IntlProviderWrapper } from './utility/context/Internationalization';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Loading-spinner';

// ** Service Worker
import * as serviceWorker from './serviceWorker';
import { history } from './utility/history';


// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <BrowserRouter>
              <Switch>
                <LazyApp />
              </Switch>
            </BrowserRouter>

            <ToastContainer newestOnTop />
          </IntlProviderWrapper>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
// ReactDOM.render(
//   <Provider store={store}>
//     <Suspense fallback={<Spinner />}>
//       <ThemeContext>
//         <LazyApp />
//         <ToastContainer newestOnTop />
//       </ThemeContext>
//     </Suspense>
//   </Provider>,
//   document.getElementById('root')
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
