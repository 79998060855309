// **  Initial State
const initialState = {
  userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.data,
        loginData: null
      };
      case "UNVERIFIED_USER":
        return {
        ...state,
        loginData: action.data,
      }
    case "LOGOUT":
      return { ...state, userData: null };
    default:
      return state;
  }
};

export default authReducer;
