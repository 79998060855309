// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import dataReducer from './dataReducer';
import chatReducer from './chatReducer';
import betsReducer from './betsReducer';
import itemsReducer from './itemsReducer';
import translationsReducer from './translationsReducer';
import ticketsReducer from './ticketsReducer';
import rolesReducer from './rolesReducer';
import eventsReducer from './eventsReducer';
import dashboardReducer from './dashboardReducer';
import paymentsReducer from './paymentsReducer';

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  data: dataReducer,
  chats: chatReducer,
  bets: betsReducer,
  items: itemsReducer,
  translations: translationsReducer,
  tickets: ticketsReducer,
  roles: rolesReducer,
  events: eventsReducer,
  dashboard: dashboardReducer,
  payments:paymentsReducer
})

export default rootReducer
